
import React, { Suspense, lazy } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import './App.scss';
import Loading from './main/components/loading/';
const Login = lazy(() => import("./base/login"));
const Register = lazy(() => import("./base/register"));
const Main = lazy(() => import("./main/"));
const ForgotPassword = lazy(() => import("./base/ForgotPassword"));

function App() {
    return (
        <div className="App root-container">
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route path="/" component={Login} exact={true} />
                    <Route path="/login" component={Login} exact={true} />
                    <Route path="/signup" component={Register} exact={true} />
                    <Route path="/forgot-password" component={ForgotPassword} exact={true} />
                    <Route component={Main} />
                </Switch>
            </Suspense>
        </div>
    );
}

export default withRouter(App);
